



















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import type { NuxtError } from '@nuxt/types';
import {
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    SfButton,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const goToStockList = async () => {
      await router.push(app.localeRoute({ name: 'stock-list' }));
    };

    return {
      goToStockList,
    }
  }
});
