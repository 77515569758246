/* eslint-disable  */

export default {
  "About us": "Über uns",
  "Account": "Konto",
  "Add new address": "Neue Adresse hinzufügen",
  "Add to compare": "Hinzufügen zum vergleichen",
  "Add to Wishlist": "Zur Wunschliste hinzufügen",
  "Additional Information": "Zusätzliche Information",
  "All Orders": "Alle Bestellungen",
  "Allow order notifications": "Bestellbenachrichtigungen zulassen",
  "Apply": "Übernehmen",
  "Applied Coupon": "Angewendeter Gutschein",
  "Attention!": "Attention!",
  "Back to home": "Zurück Zur Startseite",
  "Back to homepage": "Zurück zur Homepage",
  "Billing": "Abrechnung",
  "Billing address": "Rechnungsadresse",
  "Brand": "Marke",
  "Cancel": "Abbrechen",
  "Cart": "Warenkorb",
  "Categories": "Kategorien",
  "Change": "Änderungsänderung",
  "Change password your account": "Wenn Sie das Passwort ändern möchten, um auf Ihr Konto zuzugreifen, geben Sie die folgenden Informationen ein",
  "Change to grid view": "Zur Rasteransicht wechseln",
  "Change to list view": "Zur Listenansicht wechseln",
  "Clear all": "Alles löschen",
  "Color": "Farbe",
  "Commercial information": "und erklären sich damit einverstanden, personalisierte Handelsinformationen vom Markennamen per E-Mail zu erhalten",
  "Contact details updated": "Halten Sie Ihre Adressen und Kontaktdaten auf dem neuesten Stand.",
  "Contact us": "Kontaktiere uns",
  "Continue to billing": "Weiter zur Abrechnung",
  "Continue to payment": "Weiter zur Zahlung",
  "Continue to shipping": "Weiter zum Versand",
  "Cookies Policy": "Cookie-Richtlinie",
  "Create an account": "Konto erstellen",
  "Customer Reviews": "Kundenbewertungen",
  "Customer service": "Kundendienst",
  "Date": "Datum",
  "Default Billing Address": "Standard-Rechnungsadresse",
  "Default Shipping Address": "Standardlieferadresse",
  "Delete": "Löschen",
  "Departments": "Abteilungen",
  "Description": "Beschreibung",
  "Details and status orders": "Überprüfen Sie die Details und den Status Ihrer Bestellungen im Online-Shop. Sie können Ihre Bestellung auch stornieren oder eine Rücksendung anfordern. ",
  "Discount": "Rabatt",
  "Done": "Fertig",
  "Download": "Herunterladen",
  "Download all": "Alle herunterladen",
  "Edit": "Bearbeiten",
  "Email address": "E-Mail Adresse",
  "Empty": "Sieht so aus, als hätten Sie der Tasche noch keine Artikel hinzugefügt. Beginnen Sie mit dem Einkaufen, um es auszufüllen.",
  "Empty bag": "Leere Tasche",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Geben Sie den Promo-Code ein",
  "Feedback": "Ihr Feedback ist uns wichtig. Lassen Sie uns wissen, was wir verbessern können.",
  "Feel free to edit": "Fühlen Sie sich frei, Ihre unten stehenden Daten zu bearbeiten, damit Ihr Konto immer auf dem neuesten Stand ist",
  "Filters": "Filter",
  "Find out more": "Finde mehr heraus",
  "First Name": "Vorname",
  "Forgot Password": "Wenn Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen.",
  "Forgot Password Modal Email": "E-Mail, mit der Sie sich anmelden:",
  "forgotPasswordConfirmation": "Vielen Dank! Wenn ein Konto mit der E-Mail-Adresse {0} registriert ist, finden Sie in Ihrem Posteingang eine Nachricht mit einem Link zum Zurücksetzen des Passworts.",
  "Forgotten password?": "Passwort vergessen?",
  "Go back": "Geh zurück",
  "Go back shopping": "Zurück einkaufen",
  "Go back to shop": "Zurück zum Einkaufen",
  "Go to checkout": "Zur Kasse gehen",
  "Go to cart": "Zur Warenkorb gehen",
  "Guarantee": "Garantie",
  "Help": "Hilfe",
  "Help & FAQs": "Hilfe & FAQs",
  "hide": "ausblenden",
  "Home": "Startseite",
  "I agree to": "Ich stimme zu",
  "I confirm subscription": "Ich bestätige das Abonnement",
  "I want to create an account": "Ich möchte ein Konto erstellen",
  "Info after order": "Sie können sich mit E-Mail und definiertem Passwort in Ihrem Konto anmelden vorhin. Überprüfen Sie in Ihrem Konto Ihre Profildaten Transaktionsverlauf, Abonnement für Newsletter bearbeiten.",
  "Instruction1": "Um mich kümmern",
  "Instruction2": "Nur hier für die Pflegehinweise?",
  "Instruction3": "Ja, das haben wir uns gedacht",
  "It was not possible to request a new password, please check the entered email address.": "Es war nicht möglich, ein neues Passwort anzufordern, bitte überprüfen Sie die eingegebene E-Mail-Adresse.",
  "Item": "Artikel",
  "Items": "Gegenstände",
  "Kidswear": "Kinderkleidung",
  "Last Name": "Nachname",
  "Let’s start now – we’ll help you": "Fangen wir jetzt an - wir helfen Ihnen.",
  "Log into your account": "In dein Konto einloggen",
  "Login": "Anmeldung",
  "login in to your account": "Anmelden bei Ihrem Konto",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Sieht so aus, als hätten Sie der Tasche noch keine Artikel hinzugefügt. Beginnen Sie mit dem Einkaufen, um es auszufüllen.",
  "Looks like you haven’t added any items to the Wishlist.": "Sieht so aus, als hätten Sie keine Artikel zur Wunschliste hinzugefügt.",
  "Make an order": "Bestellung aufgeben",
  "Manage addresses": "Verwalten Sie alle gewünschten Adressen (Arbeitsplatz, Privatadresse...) Auf diese Weise müssen Sie die Adresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage billing addresses": "Alle gewünschten Rechnungsadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Rechnungsadresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage shipping addresses": "Alle gewünschten Versandadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Versandadresse nicht bei jeder Bestellung manuell eingeben.",
  "Match it with": "Kombiniere es mit",
  "Men fashion": "Herrenmode",
  "Menu": "Menü",
  "My billing and shipping address are the same": "Meine Rechnungs- und Lieferadresse sind identisch",
  "My Cart": "Mein Warenkorb",
  "New Products": "Neue Produkte",
  "No account": "Sie haben noch keinen Account?",
  "or": "oder",
  "or fill the details below": "oder füllen Sie die Details unten",
  "Order ID": "Bestellung ID",
  "Order information": "Bestellinformationen",
  "Order No.": "Bestellnummer",
  "Order summary": "Bestellübersicht",
  "Other products you might like": "Andere Produkte, die Ihnen gefallen könnten",
  "Out of stock": "Nicht auf Lager",
  "Password": "Passwort",
  "Password Changed": "Passwort erfolgreich geändert. Sie können nun zur Startseite zurückkehren und sich anmelden.",
  "Pay for order": "Für Bestellung bezahlen",
  "Payment": "Zahlung",
  "Payment & Delivery": "Zahlung & Lieferung",
  "Payment Method": "Zahlungsmethode",
  "Payment Methods": "Zahlungsmethoden",
  "Personal details": "Persönliche Daten",
  "Please type your current password to change your email address.": "Bitte geben Sie Ihr aktuelles Passwort ein, um Ihre E-Mail-Adresse zu ändern.",
  "Price": "Preis",
  "Privacy policy": "Datenschutz",
  "Product": "Produkt",
  "Product description": "Das Karissa V-Neck Tee hat eine halb taillierte Form schmeichelhaft für jede Figur. Sie können mit ins Fitnessstudio gehen Vertrauen, während es Kurven umarmt und häufiges \"Problem\" verbirgt Bereiche. Finden Sie atemberaubende Cocktailkleider für Frauen und feiern Sie Kleider.",
  "Product suggestions": "Produktvorschläge",
  "Products": "Produkte",
  "Products found": "Produkte gefunden",
  "Purchase terms": "Kaufbedingungen",
  "Quality in the details": "Qualität im Detail",
  "Quantity": "Menge",
  "Read all reviews": "Alle Bewertungen lesen",
  "Read and understand": "Ich habe das gelesen und verstanden",
  "Read reviews": "Bewertungen lesen",
  "Register": "Registrieren",
  "Register today": "Registrieren",
  "Remove": "Löschen",
  "Remove Address": "Adresse entfernen",
  "Remove from Wishlist": "Von Wunschliste entfernen",
  "Repeat Password": "Wiederhole das Passwort",
  "Reset Password": "Passwort Zurücksetzen",
  "Review my order": "Meine Bestellung überprüfen",
  "Same as shipping address": "Wie Versandadresse",
  "Save changes": "Änderungen speichern",
  "Save for later": "Für später speichern",
  "Save Password": "Passwort Speichern",
  "Search": "Suche",
  "Search for items": "Nach Artikeln suchen",
  "Search results": "Suchergebnisse",
  "Sections that interest you": "Abschnitte, die Sie interessieren",
  "See all": "Alle anzeigen",
  "See all results": "Alle Ergebnisse anzeigen",
  "See more": "Mehr sehen",
  "Select payment method": "Zahlungsmethode auswählen",
  "Select shipping method": "Versandart auswählen",
  "Send my feedback": "Senden Sie mein Feedback",
  "Set up newsletter": "Richten Sie Ihren Newsletter ein und wir senden Ihnen wöchentlich Informationen zu neuen Produkten und Trends aus den von Ihnen ausgewählten Bereichen",
  "Share your look": "Teile deinen Look",
  "Shipping": "Versand",
  "Shipping address": "Lieferadresse",
  "Shipping details": "Versanddetails",
  "Shipping method": "Versandart",
  "Show":"Anzeigen",
  "show more": "mehr anzeigen",
  "Show on page": "Auf Seite anzeigen",
  "Sign in": "Einloggen",
  "Size guide": "Größentabelle",
  "Sign Up for Newsletter": "Anmeldung für Newsletter",
  "Sort by": "Sortieren nach",
  "Sort: Default": "Standard",
  "Sort: Name A-Z": "Benennen Sie A-Z",
  "Sort: Name Z-A": "Benennen Sie Z-A",
  "Sort: Price from high to low": "Preis von hoch bis niedrig",
  "Sort: Price from low to high": "Preis von niedrig bis hoch",
  "Start shopping": "Einkaufen starten",
  "Status": "Status",
  "Subscribe": "Abonnieren",
  "Subscribe to newsletter": "Anmeldung zum Newsletter",
  "subscribeToNewsletterModalContent": "Wenn Sie sich für den Newsletter angemeldet haben, erhalten Sie spezielle Angebote und Nachrichten von VSF per E-Mail. Wir werden Ihre E-Mail zu keinem Zeitpunkt an Dritte verkaufen oder weitergeben. Bitte beachten Sie unsere {0}.",
  "Subtotal": "Zwischensumme",
  "Subtotal price": "Zwischensumme Preis",
  "Successful placed order": "Sie haben die Bestellung erfolgreich aufgegeben. Sie können den Status Ihres Bestellen Sie über unsere Lieferstatusfunktion. Sie erhalten eine Bestellung Bestätigungs-E-Mail mit Details Ihrer Bestellung und einem Link zum Verfolgen der Bestellung Fortschritt.",
  "Terms and conditions": "AGB",
  "Thank you": "Danke",
  "Thank You Inbox": "Wenn die Nachricht nicht in Ihrem Posteingang ankommt, versuchen Sie es mit einer anderen E-Mail-Adresse, mit der Sie sich möglicherweise registriert haben.",
  "Total": "Gesamt",
  "Order Total": "Auftragssumme",
  "Total items": "Gesamtanzahl",
  "Total price": "Gesamtpreis",
  "Update password": "Passwort aktualisieren",
  "Update personal data": "Persönliche Daten aktualisieren",
  "Use your personal data": "Bei Markennamen legen wir großen Wert auf Datenschutzfragen und verpflichten uns, die persönlichen Daten unserer Benutzer zu schützen. Erfahren Sie mehr darüber, wie wir Ihre persönlichen Daten pflegen und verwenden",
  "User Account": "Benutzerkonto",
  "View": "Ansicht",
  "View details": "Details anzeigen",
  "Vue Storefront Next": "Vue Storefront Next",
  "We haven’t found any results for given phrase": "Wir haben keine Ergebnisse für die angegebene Phrase gefunden",
  "Who we are": "Wer wir sind",
  "Wishlist": "Wunschliste",
  "Women fashion": "Damenmode",
  "You added {product} to your shopping cart.": "Sie haben {product} zu Ihrem Warenkorb hinzugefügt.",
  "You are not authorized, please log in": "Sie sind nicht autorisiert, bitte melden Sie sich an",
  "You can unsubscribe at any time": "Sie können sich jederzeit abmelden",
  "You currently have no orders": "Sie haben derzeit keine Bestellungen",
  "The email field must be a valid email":"Das E-Mail-Feld muss eine gültige E-Mail-Adresse sein",
  "You haven’t searched for items yet": "Sie haben noch nicht nach Artikeln gesucht.",
  "Your bag is empty": "Ihre Tasche ist leer",
  "Your current email address is": "Ihre aktuelle E-Mail-Adresse lautet",
  "Your email": "Deine E-Mail",
  "You have submitted no reviews": "Sie haben keine Bewertungen abgegeben",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Die Kontoanmeldung war falsch oder Ihr Konto wurde vorübergehend deaktiviert. Bitte warten Sie und versuchen Sie es später erneut.",
  "A customer with the same email address already exists in an associated website.": "Ein Kunde mit derselben E-Mail-Adresse existiert bereits auf einer verknüpften Website.",
  "Invalid email": "Ungültige E-Mail",
  "SUMMER COLLECTION {year}": "SOMMERKOLLEKTION {year}",
  "Colorful summer dresses are already in store": "Bunte Sommerkleider sind bereits im Store",
  "Learn more": "Mehr erfahren",
  "Dresses": "Kleider",
  "Cocktail & Party": "Cocktailparty",
  "Linen Dresses": "Leinenkleider",
  "T-Shirts": "T-Shirts",
  "The Office Life": "Das Büroleben",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Finden Sie atemberaubende Cocktailkleider für Damen und Cocktailkleider für Party-D…c von all Ihren Lieblingsmarken.",
  "Shop now": "Jetzt einkaufen",
  "Summer Sandals": "Sommersandalen",
  "Eco Sandals": "Öko-Sandalen",
  "Subscribe to Newsletters": "Newsletter abonnieren",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Informieren Sie sich über bevorstehende Verkäufe und Veranstaltungen. Erhalten Sie Geschenke und Sonderangebote",
  "Fashion to take away": "Mode zum Mitnehmen",
  "Download our application to your mobile": "Laden Sie unsere Anwendung auf Ihr Handy herunter",
  "Share Your Look": "Teilen Sie Ihren Look",
  "My Account": "Mein Konto",
  "My profile": "Mein Profil",
  "Personal Details": "Persönliche Daten",
  "Addresses details": "Adressendetails",
  "My newsletter": "Mein Newsletter",
  "Log out": "Ausloggen",
  "My reviews": "Meine Bewertungen",
  "Order history": "Bestellverlauf",
  "Order details": "Bestelldetails",
  "My wishlist": "Meine Wunschliste",
  "Password change": "Passwortänderung",
  "Personal data": "Persönliche Daten",
  "Your e-mail": "Deine E-Mail",
  "Current Password": "Jetziges Passwortd",
  "You are not authorized, please log in.": "Sie sind nicht autorisiert, bitte melden Sie sich an.",
  "Go To Product": "Zum Produkt",
  "Returns":"Rücklauf",
  "My orders": "Meine Bestellungen",
  "Add the address": "Fügen Sie die Adresse hinzu",
  "Set as default shipping": "Als Standardversand festlegen",
  "Set as default billing": "Als Standardabrechnung festlegen",
  "House/Apartment number": "Haus-/Wohnungsnummer",
  "Street Name": "Straßenname",
  "City": "Stadt",
  "State/Province": "Staat/Provinz",
  "Zip-code": "Postleitzahl",
  "Country": "Land",
  "Phone number": "Telefonnummer",
  "Please select a country first": "Bitte wählen Sie zuerst ein Land aus",
  "This field is required": "Dieses Feld ist erforderlich",
  "The field should have at least 2 characters": "Das Feld sollte mindestens 2 Zeichen lang sein",
  "The field should have at least 4 characters": "Das Feld sollte mindestens 4 Zeichen lang sein",
  "The field should have at least 8 characters": "Das Feld sollte mindestens 8 Zeichen lang sein",
  "New Password": "Neues Passwort",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Für dieses Land sind keine Versandarten verfügbar. Es tut uns leid, bitte versuchen Sie es mit einem anderen Land oder später.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Beim Versuch, Versandarten abzurufen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit anderen Versanddetails oder später.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Beim Versuch, diese Versandart auszuwählen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit einer anderen Versandart.",
  "We can't find products matching the selection.":"Wir können keine Produkte finden, die der Auswahl entsprechen.",
  'Page not found': 'Seite nicht gefunden',
  'Back to Home page': 'Zurück zur Startseite',
  'An error occurred': 'Ein Fehler ist aufgetreten',
  "AllProductsFromCategory": "Alle {categoryName}",
  "Show more": "Zeig mehr",
  "Show less": "Zeige weniger",
  "Yes": "Ja",
  "No": "Nein",
  "Apply filters": "Filter anwenden",
  "The coupon code isn't valid. Verify the code and try again.": "Der Gutscheincode ist ungültig. Überprüfen Sie den Code und versuchen Sie es erneut.",
  "From": "Aus",
  "To": "Zu",
  "Your customization": "Ihre Anpassung",
  "Passwords don't match":"Passwörter stimmen nicht überein",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens enthalten: 1 Groß- oder Kleinbuchstabe, 1 Ziffer oder ein Sonderzeichen (z. B. , . _ & ? usw.)",
  "Show all products":"Alle Produkte anzeigen",
  "Select previously saved address":"Zuvor gespeicherte Adresse auswählen",
  "Enter different address":"Geben Sie eine andere Adresse ein",
  "You must confirm your account. Please check your email for the confirmation link.": "Sie müssen Ihr Konto bestätigen. Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungslink.",
  "Change Store":"Laden Wechseln",
  "Choose Currency":"Währung wählen",
  "Add a review":"Bewertung hinzufügen",
  "Add to cart":"Zum Warenkorb",
  "Title":"Titel",
  "Name":"Name",
  "Review":"Rezension",
  "Add review":"Bewertung hinzufügen",
  "Are you sure you would like to remove this item from the shopping cart?":"Sind Sie sicher, dass Sie diesen Artikel aus dem Warenkorb entfernen möchten?",
  "Your cart is empty":"Ihr Warenkorb ist leer",
  "Are you sure?":"Bist du dir sicher?",
  "{0} has been successfully removed from your cart":"{0} wurde erfolgreich aus Ihrem Warenkorb entfernt",
  "Amount":"Menge",
  "Thank you for your order!":"Vielen Dank für Ihren Auftrag!",
  "Your Purchase":"Ihr Einkauf",
  "Primary contacts for any questions":"Primäre Ansprechpartner für alle Fragen",
  "Your Account":"Ihr Konto",
  "What can we improve":"Was können wir verbessern",
  "Payment date":"Zahlungsdatum",
  "The user password was changed successfully updated!":"Das Benutzerpasswort wurde erfolgreich geändert aktualisiert!",
  "The user account data was successfully updated!":"Die Benutzerkontodaten wurden erfolgreich aktualisiert!",
  "You submitted your review for moderation.": "Sie haben Ihre Bewertung zur Moderation eingereicht.",
  "Starting at": "Beginnt um",
  "Additional description":"Zusatzinfo",
  "Availability":"Verfügbarkeit",
  "Delivery time":"Lieferzeit",
  "Packaging Unit":"Verpackungseinheit",
  "Folies":"Folien",
  "Dimensions":"Maße",
  "Item number":"Artikelnummer",
  "Reset":"Zurücksetzen",
  "Stocklist":"Lagerliste",
  "BEN-PLASTICS":"BEN-KUNSTSTOFFE",
  "Pieces":"Stück",
  "Kg":"Kg",
  "Add piece by piece":"Stückweise Hinzufügen",
  "One pallet contains ":"Eine Palette enthält ",
  "Your selection corresponds to a total weight of ":"Ihre Auswahl entspricht einem Gesamtgewicht von ",
  "Add":"Hinzufügen",
  "Add palette by palette":"Palettenweise Hinzufügen",
  "pallets,":"Paletten entsprechen,",
  "mm":"mm",
  "The item has been added!":"Der Artikel wurde hinzugefügt!",
  "Materials":"Werkstoffe",
  "Material":"Material",
  "Length (mm)":"Länge (mm)",
  "Width (mm)":"Breite (mm)",
  "Strength (mm)":"Stärke (mm)",
  "#":"#",
  "Delivery weight":"Liefergewicht",
  "Delete cart":"Warenkorb löschen",
  "Summary":"Zusammenfassung",
  "Total weight:":"Gesamtgewicht:",
  "Request for quotation":"Angebotsanfrage",
  "The offer will be sent to the specified e-mail address.":"Das Angebot wird an die angegeben E-Mail Adresse gesendet.",
  "Request":"Anfordern",
  "If you delete all items, you will be redirected to the stock page.":"Wenn Sie alle Artikel löschen werden sie auf die Lagerseite weitergeleitet.",
  "Remaining until the next PU":"verbleibend bis zur nächsten VE",
  "Fill pallet":"Palette Auffüllen",
  "All Materials":"Alle Materialien",
  "All Products":"Alle Produkte",
  "All Lengths":"Alle Längen",
  "All Widths":"Alle Breiten",
  "All Strengths":"Alle Stärken",
  "All Colors":"Alle Farben",
  "Company":"Unternehmen",
  "Sites":"Standorte",
  "Quality":"Qualität",
  "Logistics & Services":"Logistik & Service",
  "Sheets extruded":"Platten extrudiert",
  "MELOR increased impact resistance":"MELOR erhöht schlagzäh",
  "MEFLEX 500 strongyl increased impact strength":"MEFLEX 500 stark erhöht schlagzäh",
  "MEFLEX 1000 extremely impact resistant":"MEFLEX 1000 extrem schlagzäh",
  "MEDUR TZ thermoforming quality":"MEDUR TZ Tiefziehqualität",
  "MEDUR LT food safe":"MEDUR LT lebensmittelunbedenklich",
  "MEFLAM with fire safety certificate":"MEFLAM mit Brandschutzzeugnis",
  "MEFOL decorative coating":"MEFOL Dekorbeschichtung",
  "MEPRINT print quality":"MEPRINT Druckqualität",
  "MEDUR R regenerate":"MEDUR R Regenerat",
  "MEPOLEN S (PP-S) flame retardant":"MEPOLEN S (PP-S) schwer entflammbar",
  "MEPOLEN Pool (PP-C) swimming pool quality":"MEPOLEN Pool (PP-C) Schwimmbadqualität",
  "MEPOLEN Copo F (PP-C) co-extruded foamed":"MEPOLEN Copo F (PP-C) co-extrudiert geschäumt",
  "MEPOLEN R regenerate":"MEPOLEN R Regenerat",
  "MELEN 100 (PE-100) pressure vessel quality (DIBt)":"MELEN 100 (PE-100) Druckbehälterqualität (DIBt)",
  "MELEN R regenerate":"MELEN R Regenerat",
  "Plates pressed":"Platten gepresst",
  "MEDUR Press (PVC-U pressed)":"MEDUR Press (PVC-U gepresst)",
  "MEDUR Press C (PVC-C post-chlorinated)":"MEDUR Press C (PVC-C nachchloriert)",
  "MEPOLEN Press (PP-H pressed)":"MEPOLEN Press (PP-H gepresst)",
  "MELEN Press (PE-HD pressed)":"MELEN Press (PE-HD gepresst)",
  "MELEN 500 (PE-HMW pressed)":"MELEN 500 (PE-HMW gepresst)",
  "MELEN 500 R (PE-HMW pressed) regenrate":"MELEN 500 R (PE-HMW gepresst) Regenerat",
  "MELEN 1000 (PE-UHMW pressed)":"MELEN 1000 (PE-UHMW gepresst)",
  "MELEN 1000 R (PE-UHMW pressed) regenerate":"MELEN 1000 R (PE-UHMW gepresst) Regenerat",
  "Rods / welding rod":"Stäbe / Schweißdraht",
  "MEDUR (PVC-U) solid bars extruded":"MEDUR (PVC-U) Vollstäbe extrudiert",
  "MEDUR (PVC-U) hollow bars extruded":"MEDUR (PVC-U) Hohlstäbe extrudiert",
  "MEDUR (PVC-U) welding bars extruded":"MEDUR (PVC-U) Schweißdraht extrudiert",
  "MEPOLEN (PP-H) solid bars extruded":"MEPOLEN (PP-H) Vollstäbe extrudiert",
  "MEPOLEN (PP) welding bars extruded":"MEPOLEN (PP) Schweißdraht extrudiert",
  "MELEN (PE-HD) solid bars extruded":"MELEN (PE-HD) Vollstäbe extrudiert",
  "MELEN (PE-HD) welding bars extruded":"MELEN (PE-HD) Schweißdraht extrudiert",
  "MELEN 100 (PE 100) welding bars extruded":"MELEN 100 (PE 100) Schweißdraht extrudiert",
  "Foam boards":"Schaumplatten",
  "PVC foam sheets":"PVC-Schaumplatten",
  "METEX (PVC FF) free foam board":"METEX (PVC FF) Freischaumplatte",
  "MERAL (PVC IF) integral foam board":"MERAL (PVC IF) Integralschaumplatte",
  "Prefabricated baords":"Fertigteile",
  "Industries":"Branchen",
  "Overview":"Branchenübersicht",
  "Swimming pool and pool construction":"Schwimmbad- und Poolbau",
  "Ice hockey":"Eishockey",
  "Orthopedics":"Orthopädie",
  "Venitlation systems":"Lüftungsanlagen",
  "Vessel and apparatus engineering":"Behälter- und Apparatebau",
  "Conveyor technology":"Fördertechnik",
  "Playground construction and leisure":"Spielplatzbau und Freizeit",
  "Career":"Karriere",
  "Career at BEN": "Karriere",
  "Contact":"Kontakt",
  "https://www.ben-plastic.com/en/company/about-us":"https://www.ben-plastic.com/unternehmen/ueber-uns",
  "https://www.ben-plastic.com/en/company/sites":"https://www.ben-plastic.com/unternehmen/standorte",
  "https://www.ben-plastic.com/en/company/quality":"https://www.ben-plastic.com/unternehmen/qualitaet",
  "https://www.ben-plastic.com/en/company/logistics-service":"https://www.ben-plastic.com/unternehmen/logistik-service",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/medur-standard":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/medur-standardqualitaet",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/melor-increased-impact-resistance":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/melor-erhoeht-schlagzaeh",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/meflex-500-strongly-increased-impact-strength":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/meflex-500-stark-erhoeht-schlagzaeh",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/meflex-1000-extremely-impact-resistant":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/meflex-1000-pvc-u-extrem-schlagzaeh",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/medur-tz-thermoforming-quality":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/medur-tz-tiefziehqualitaet",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/medur-lt-food-safe":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/medur-lt-lebensmittelunbedenklich",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/meflam-with-fire-safety-certificate":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/meflam-mit-brandschutzzeugnis",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/mefol-decorative-coating":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/mefol-dekorbeschichtung",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/meprint-print-quality":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/meprint-druckqualitaet",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/metransparent-transparent":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/metransparent",
  "https://www.ben-plastic.com/en/products/sheets-extruded/medur-pvc-u/medur-r-regenerate":"https://www.ben-plastic.com/produkte/platten-extrudiert/medur-pvc-u/medur-r",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-pp-h-standard":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-pp-h-standard",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-s-pp-s-flame-retardant":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-s-pp-s-schwer-entflammbar",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-copo-pp-c-standard":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-copo-pp-c-standard",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-pool-pp-c-swimming-pool-quality":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-pool-pp-c-schwimmbadqualitaet",
  "https://www.ben-plastic.com/en/produkte/platten-extrudiert/mepolen-pp/mepolen-ortho-pp-h":"https://www.ben-plastic.com/products/sheets-extruded/mepolen-pp/mepolen-ortho-pp",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-g-uni":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-g-uni",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-coex":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-coex",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-ast":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-ast",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-el":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-el",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-s-el":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-s-el",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-copo-f-pp-c-co-extruded-foamed":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-copo-f-pp-c-co-extrudiert-geschaeumt",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-tv":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-tv",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-gk":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-gk",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-sk":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-sk",
  "https://www.ben-plastic.com/en/products/sheets-extruded/mepolen-pp/mepolen-r-regenerate":"https://www.ben-plastic.com/produkte/platten-extrudiert/mepolen-pp/mepolen-r",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-pe-hd-standard":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-pe-hd-standard",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-100-pe-100-pressure-vessel-quality-dibt":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-100-pe-100-dibt-druckbehaelterqualitaet",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-100-rc-pe-100-rc":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-100-rc-pe-100-rc",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-g-uni":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-g-uni",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-play-uni":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-play-pe-hd-spielgeraetequalitaet",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-play-coex":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-play-coex",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-graph-coex":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-graph-coex",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-antislip":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-antislip",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-ortho-pe":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-ortho-pe-hd",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-ast":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-ast",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-el":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-el",
  "https://www.ben-plastic.com/en/products/sheets-extruded/melen-pe/melen-r-regenerate":"https://www.ben-plastic.com/produkte/platten-extrudiert/melen-pe-hd/melen-r",
  "https://www.ben-plastic.com/en/products/plates-pressed/medur-press-pvc-u/medur-press-pvc-u-pressed":"https://www.ben-plastic.com/produkte/platten-gepresst/medur-press-pvc-u/medur-press-pvc-u-gepresst",
  "https://www.ben-plastic.com/en/products/plates-pressed/medur-press-pvc-u/medur-press-c-pvc-c-post-chlorinated":"https://www.ben-plastic.com/produkte/platten-gepresst/medur-press-pvc-u/medur-press-c-pvc-c-nachchloriert",
  "https://www.ben-plastic.com/en/products/plates-pressed/mepolen-press-pp/mepolen-press-pp-h-pressed":"https://www.ben-plastic.com/produkte/platten-gepresst/press-pp/mepolen-press-pp-h-gepresst",
  "https://www.ben-plastic.com/en/products/plates-pressed/melen-press-pe/melen-press-pe-hd-pressed":"https://www.ben-plastic.com/produkte/platten-gepresst/press-pe/melen-press-pe-hd-gepresst",
  "https://www.ben-plastic.com/en/products/plates-pressed/melen-press-pe/melen-500-pe-hmw-pressed":"https://www.ben-plastic.com/produkte/platten-gepresst/press-pe/melen-500-pe-hmw-gepresst",
  "https://www.ben-plastic.com/en/products/plates-pressed/melen-press-pe/melen-500-r-pe-hmw-pressed-regenerate":"https://www.ben-plastic.com/produkte/platten-gepresst/press-pe/melen-500-r-pe-hmw-gepresst-regenerat",
  "https://www.ben-plastic.com/en/products/plates-pressed/melen-press-pe/melen-1000-pe-uhmw-pressed":"https://www.ben-plastic.com/produkte/platten-gepresst/press-pe/melen-1000-pe-uhmw-gepresst",
  "https://www.ben-plastic.com/en/products/plates-pressed/melen-press-pe/melen-1000-r-pe-uhmw-pressed-regenerate":"https://www.ben-plastic.com/produkte/platten-gepresst/press-pe/melen-1000-r-pe-uhmw-gepresst-regenerat",
  "https://www.ben-plastic.com/en/products/rods-/-welding-rod/medur-pvc-u/medur-pvc-u-solid-bars-extruded":"https://www.ben-plastic.com/produkte/staebe/medur-pvc-u/medur-pvc-u-vollstaebe-extrudiert",
  "https://www.ben-plastic.com/en/products/rods-/-welding-rod/medur-pvc-u/medur-pvc-u-hollow-bars-extruded":"https://www.ben-plastic.com/produkte/staebe/medur-pvc-u/hohlstaebe-extrudiert",
  "https://www.ben-plastic.com/en/products/rods-/-welding-rod/medur-pvc-u/medur-pvc-u-welding-rod-extruded":"https://www.ben-plastic.com/produkte/staebe/medur-pvc-u/medur-pvc-u-schweissdraht-extrudiert",
  "https://www.ben-plastic.com/en/products/rods-/-welding-rod/mepolen-pp/mepolen-pp-h-solid-bars-extruded":"https://www.ben-plastic.com/produkte/staebe/mepolen-pp-h/mepolen-pp-h-vollstaebe-extrudiert",
  "https://www.ben-plastic.com/en/products/rods-/-welding-rod/mepolen-pp/mepolen-pp-welding-rod-extruded":"https://www.ben-plastic.com/produkte/staebe/mepolen-pp-h/mepolen-pp-schweissdraht-extrudiert",
  "https://www.ben-plastic.com/en/produkte/staebe/melen-pe-hd/melen-pe-hd-solid-bars-extruded":"https://www.ben-plastic.com/produkte/staebe/melen-pe-hd/melen-pe-hd-vollstaebe-extrudiert",
  "https://www.ben-plastic.com/en/produkte/staebe/melen-pe-hd/melen-pe-hd-welding-rod-extruded":"https://www.ben-plastic.com/produkte/staebe/melen-pe-hd/melen-pe-hd-schweissdraht-extrudiert",
  "https://www.ben-plastic.com/en/produkte/staebe/melen-pe-hd/melen-100-pe-100-welding-rod-extruded":"https://www.ben-plastic.com/produkte/staebe/melen-pe-hd/melen-100-pe-100-schweissdraht-extrudiert",
  "https://www.ben-plastic.com/en/products/foam-boards/pvc-foam-sheets/metex-pvc-ff-free-foam-board":"https://www.ben-plastic.com/produkte/schaumplatten/pvc-schaum/metex-pvc-ff-freischaumplatte",
  "https://www.ben-plastic.com/en/products/foam-boards/pvc-foam-sheets/meral-pvc-if-integral-foam-board":"https://www.ben-plastic.com/produkte/schaumplatten/pvc-schaum/meral-pvc-if-integralschaumplatte",
  "https://www.ben-plastic.com/en/products/prefabricated-parts":"https://www.ben-plastic.com/produkte/fertigteile",
  "https://www.ben-plastic.com/en/industries/overview/swimming-pool-and-pool-construction":"https://www.ben-plastic.com/branchen/uebersicht/schwimmbad-und-poolbau",
  "https://www.ben-plastic.com/en/industries/overview":"https://www.ben-plastic.com/branchen/uebersicht",
  "https://www.ben-plastic.com/en/industries/overview/funsport":"https://www.ben-plastic.com/branchen/uebersicht/funsport",
  "https://www.ben-plastic.com/en/industries/overview/ice-hockey":"https://www.ben-plastic.com/branchen/uebersicht/eishockey",
  "https://www.ben-plastic.com/en/industries/overview/orthopedics":"https://www.ben-plastic.com/branchen/uebersicht/orthopaedie",
  "https://www.ben-plastic.com/en/industries/overview/ventilation-systems":"https://www.ben-plastic.com/branchen/uebersicht/lueftungsanlagen",
  "https://www.ben-plastic.com/en/industries/overview/vessel-and-apparatus-engineering":"https://www.ben-plastic.com/branchen/uebersicht/behaelter-und-apparatebau",
  "https://www.ben-plastic.com/en/industries/overview/conveyor-technology":"https://www.ben-plastic.com/branchen/uebersicht/foerdertechnik",
  "https://www.ben-plastic.com/en/industries/overview/playground-construction-and-leisure":"https://www.ben-plastic.com/branchen/uebersicht/spielplatzbau-und-freizeit",
  "https://www.ben-plastic.com/en/downloads":"https://www.ben-plastic.com/downloads",
  "https://www.ben-plastic.com/en/contact":"https://www.ben-plastic.com/kontakt",
  "https://www.ben-plastic.com/unternehmen/news":"https://www.ben-plastic.com/unternehmen/news",
  "News": "News",
  "MEFLEX 500 strongly increased impact strength": "MEFLEX 500 strongly increased impact strength",
  "MEPOLEN Copo (PP-C) Standard": "MEPOLEN Copo (PP-C) Standard",
  "https://www.ben-plastic.com/en/kontakt": "https://www.ben-plastic.com/en/kontakt",
  "https://www.ben-plastic.com/branchen/uebersicht/lueftungsanlagen":"https://www.ben-plastic.com/branchen/uebersicht/lueftungsanlagen",
  "https://www.ben-plastic.com/branchen/uebersicht/schwimmbad-und-poolbau":"https://www.ben-plastic.com/branchen/uebersicht/schwimmbad-und-poolbau",
  "Thank you for your inquiries, we will get back to you as soon as possible.":"Vielen Dank für Ihre Anfrage, wir werden uns so schnell wie möglich bei Ihnen melden.",
  "Lengths":"Längen",
  "Widths":"Breiten",
  "Strengths":"Stärken",
  "Colors":"Farben",
  "Imprint": "Impressum",
  "https://www.ben-plastic.com/en/terms-and-conditions": "https://www.ben-plastic.com/agb",
  "https://www.ben-plastic.com/en/imprint": "https://www.ben-plastic.com/impressum",
  "https://www.ben-plastic.com/en/privacy-policy": "https://www.ben-plastic.com/datenschutz",
  "Create new password": "Neues Passwort erstellen",
  "Your password reset link has expired": "Ihr Link zum Zurücksetzen des Passworts ist abgelaufen",
  "Your password change was successful": "Ihre Passwortänderung war erfolgreich",
  "The passwords do not match": "Die Passwörter stimmen nicht überein",
  "Change password":"Passwort ändern",
  "Upon request":"Auf Anfrage",
  "Back to stocklist":"Zurück zur Lagerliste",
  "In Stock":"Auf Lager",
  "Out of Stock":"Nicht auf Lager",
  "x":"x",
  "Packung unit":"Verpackungseinheit",
  "Plate":"Platte",
  "1. User Details":"1. Kunden Daten",
  "2. Billing Address":"2. Rechnungsadresse",
  "3. Shipping Address":"3. Lieferadresse",
  "Continue to Billing Address":"Weiter zur Rechnungsadresse",
  "Continue to Shipping Address":"Weiter zur Lieferadresse",
  "The account is already active.":"Das Konto ist bereits aktiv.",
  "Confirm email":"E-Mail bestätigen",
  "successful confirmed.":"erfolgreich bestätigt.",
  "Register User":"Benutzer anlegen",
  "Your password has been reset and a link to set up a new password has been sent to you by email.":"Ihr Passwort wurde zurückgesetzt, ein Link zum einrichten eines neuen Passwortes wurde ihnen per Email gesendet.",
  "Your password could not be reset, please contact support!":"Ihr Passwort konnte nicht zurückgesetzt werden, bitte kontaktieren Sie den Support!",
  "Street":"Straße",
  "Same as Shipping":"Entspricht der Lieferadresse",
  "Update Address":"Adresse aktualisieren",
  "Billing address same as Shipping address":"Rechnungsadresse entspricht Lieferadresse",
  "Default billing address":"Standard Rechnungsadresse",
  "Default shipping address":"Standard Lieferadresse",
  "No address available":"Keine Adresse verfügbar",
  "Logout":"Abmelden",
  "Email":"Email",
  "Orders":"Bestellungen",
  "Addresses":"Adressen",
  "User data":"Benutzerdaten",
  "No address available.":"Keine Adresse verfügbar.",
  "Telephone":"Telefon",
  "Select at least one default address type":"Wählen Sie mindestens eine Standartadresse aus",
  "Add Address":"Adresse hinzufügen",
  "Update":"Aktualisieren",
  "New Address":"Neue Adresse",
  "Please add a shipping address":"Bitte geben Sie eine Lieferadresse an",
  "Please add a billing address":"Bitte geben Sie eine Rechnungsadresse an",
  "In stock":"Auf Lager",
  "For a successful request, a standard billing and delivery address must be provided.":"Für eine erfolgreiche Anfrage muss eine Standart Rechnungsadresse und Standart Lieferadresse hinterlegt werden.",
  "1-2 Tage":"1-2 Tage",
  "2-3 Tage":"2-3 Tage",
  "3-5 Tage":"3-5 Tage",
  "5-7 Tage":"5-7 Tage",
};
