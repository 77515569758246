































































































































































































































































































































































































































































































































































































import {
  ref,
  defineComponent,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import BenLogo from '~/components/BenNav/Logo.vue';
import StoreControl from '~/components/BenNav/StoreControl.vue';

export default defineComponent({
  components: {
    BenLogo,
    StoreControl
  },
  setup() {
    const { app } = useContext();
    const { state } = app.context.$vsf.$magento.config;
    const storeView = state.getStore();
    const mobileMenu = ref(false);
    const dropCompany = ref(false);
    const dropProducts = ref(false);
    const dropIndustries = ref(false);
    const dropCareer = ref(false);
    const dropMedur = ref(false);
    const dropMepolen = ref(false);
    const dropMelen = ref(false);
    const dropMedurPress = ref(false);
    const dropMepolenPress = ref(false);
    const dropMelenPress = ref(false);
    const dropMedurRods = ref(false);
    const dropMepolenRods = ref(false);
    const dropMelenRods = ref(false);
    const dropFoamBoards = ref(false);
    const selectedLang = ref('');

    const mobileMenuHandler = () => {
      mobileMenu.value = !mobileMenu.value;
    };
    const dropDownHandler = (value: any) => {
      if (value === 'company') {dropCompany.value = !dropCompany.value} else dropCompany.value = false;
      if (value === 'products') {dropProducts.value = !dropProducts.value} else dropProducts.value = false;
      if (value === 'industries') {dropIndustries.value = !dropIndustries.value} else dropIndustries.value = false;
      if (value === 'career') {dropCareer.value = !dropCareer.value} else dropCareer.value = false;
    };
    const dropDownSubHandler = (value: any) => {
      if (value === 'medur') return dropMedur.value = !dropMedur.value;
      if (value === 'mepolen') return dropMepolen.value = !dropMepolen.value;
      if (value === 'melen') return dropMelen.value = !dropMelen.value;
      if (value === 'medurPress') return dropMedurPress.value = !dropMedurPress.value;
      if (value === 'mepolenPress') return dropMepolenPress.value = !dropMepolenPress.value;
      if (value === 'melenPress') return dropMelenPress.value = !dropMelenPress.value;
      if (value === 'medurRods') return dropMedurRods.value = !dropMedurRods.value;
      if (value === 'mepolenRods') return dropMepolenRods.value = !dropMepolenRods.value;
      if (value === 'melenRods') return dropMelenRods.value = !dropMelenRods.value;
      if (value === 'foamBoards') return dropFoamBoards.value = !dropFoamBoards.value;
    }
    const arrowHandler = (value: any) => {
      return value ? 'arrow-open' : 'arrow-close';
    }
    const menuIconHandler = (value: any) => {
      if (value == 'menu-icon1') {
        return mobileMenu.value ? 'icon-open1' : 'icon-close1';
      }
      if (value == 'menu-icon2') {
        return mobileMenu.value ? 'icon-open2' : 'icon-close2';
      }
      if (value == 'menu-icon3') {
        return mobileMenu.value ? 'icon-open3' : 'icon-close3';
      }
    }

    const goToGermanSite = () => {
      window.location.href = '/de';
    }

    const goToEnglishSite = () => {
      window.location.href = '/en';
    }

    onMounted(() => {
      storeView === 'de' ? selectedLang.value = 'de' : selectedLang.value = 'en';
    });

    return {
      goToGermanSite,
      goToEnglishSite,
      storeView,
      mobileMenuHandler,
      dropDownHandler,
      dropDownSubHandler,
      arrowHandler,
      menuIconHandler,
      mobileMenu,
      dropCompany,
      dropProducts,
      dropIndustries,
      dropCareer,
      dropMedur,
      dropMepolen,
      dropMelen,
      dropMedurPress,
      dropMepolenPress,
      dropMelenPress,
      dropMedurRods,
      dropMepolenRods,
      dropMelenRods,
      dropFoamBoards,
      selectedLang,
    };
  },
});
