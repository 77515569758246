import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4f388a25 = () => interopDefault(import('../modules/stocklist/pages/StockList.vue' /* webpackChunkName: "" */))
const _ac50eeaa = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _e503b35e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _27a262c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _ea43beb0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _4d358908 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7a19f1ba = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _90e710f8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _6feb273d = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _c0677d72 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccountOld.vue' /* webpackChunkName: "" */))
const _bdb2f244 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _a0b36474 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _51c00fda = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _51c4725e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _34025bdc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _614cbb1e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _e4866bac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0a594199 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _39e18bf0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _27318dd6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _0bf0faec = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _334aaf70 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _eb2722da = () => interopDefault(import('../components/ProposeResult.vue' /* webpackChunkName: "" */))
const _3ccc92f4 = () => interopDefault(import('../modules/customer/components/PasswordReset/ResetByLink.vue' /* webpackChunkName: "" */))
const _cdcf5744 = () => interopDefault(import('../modules/customer/components/ConfirmEmail/ConfirmEmail.vue' /* webpackChunkName: "" */))
const _65c3f813 = () => interopDefault(import('../modules/customer/components/PasswordReset/CreatePassword.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _4f388a25,
    name: "stock-list___de"
  }, {
    path: "/en",
    component: _4f388a25,
    name: "stock-list___en"
  }, {
    path: "/de/cart",
    component: _ac50eeaa,
    name: "cart___de"
  }, {
    path: "/de/checkout",
    component: _e503b35e,
    name: "checkout___de",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___de"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___de"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___de"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___de"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___de"
    }]
  }, {
    path: "/de/Cms",
    component: _6feb273d,
    name: "Cms___de"
  }, {
    path: "/de/customer",
    component: _c0677d72,
    meta: {"titleLabel":"My Account"},
    name: "customer___de",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___de"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___de"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___de"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___de"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___de"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___de"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___de"
    }, {
      path: "/de/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___de"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___de"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___de"
    }]
  }, {
    path: "/de/Faq",
    component: _0bf0faec,
    name: "Faq___de"
  }, {
    path: "/de/Home",
    component: _115548ca,
    name: "Home___de"
  }, {
    path: "/de/my-account",
    component: _334aaf70,
    name: "my-account___de"
  }, {
    path: "/de/Page",
    component: _478a714b,
    name: "Page___de"
  }, {
    path: "/de/propose-result",
    component: _eb2722da,
    name: "propose-result___de"
  }, {
    path: "/en/cart",
    component: _ac50eeaa,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _e503b35e,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___en"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Cms",
    component: _6feb273d,
    name: "Cms___en"
  }, {
    path: "/en/customer",
    component: _c0677d72,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/Faq",
    component: _0bf0faec,
    name: "Faq___en"
  }, {
    path: "/en/Home",
    component: _115548ca,
    name: "Home___en"
  }, {
    path: "/en/my-account",
    component: _334aaf70,
    name: "my-account___en"
  }, {
    path: "/en/Page",
    component: _478a714b,
    name: "Page___en"
  }, {
    path: "/en/propose-result",
    component: _eb2722da,
    name: "propose-result___en"
  }, {
    path: "/de/reset-by-link/:email",
    component: _3ccc92f4,
    name: "reset-by-link___de"
  }, {
    path: "/en/reset-by-link/:email",
    component: _3ccc92f4,
    name: "reset-by-link___en"
  }, {
    path: "/de/confirm-email/:id/:key",
    component: _cdcf5744,
    name: "confirm-email___de"
  }, {
    path: "/de/create-password/:email/:token",
    component: _65c3f813,
    name: "create-password___de"
  }, {
    path: "/en/confirm-email/:id/:key",
    component: _cdcf5744,
    name: "confirm-email___en"
  }, {
    path: "/en/create-password/:email/:token",
    component: _65c3f813,
    name: "create-password___en"
  }, {
    path: "/de/:slug+",
    component: _478a714b,
    name: "page___de"
  }, {
    path: "/en/:slug+",
    component: _478a714b,
    name: "page___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
