























































































import { useUser } from '~/modules/customer/composables/useUser';
import {computed, defineComponent, onMounted, useFetch, useContext, useRouter, ref} from '@nuxtjs/composition-api';
import {useUiState} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
export default defineComponent({
  components: {
  },
  setup() {
    const { isAuthenticated, logout } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart, load: loadCart } = useCart();
    const { user, load: loadUser, loading: userLoading } = useUser();
    const router = useRouter();
    const { app } = useContext();
    const { toggleLoginModal } = useUiState();
    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };
    const gotToMyAccount = async () => {
      await router.push(app.localeRoute({ name: 'my-account' }));
    };
    const logOutUser = async () => {
      await logout({});
      await router.push(app.localeRoute({ name: 'stock-list' }));
    };
    const userName = computed (() => {
      return user && user.value?.firstname && user.value?.lastname ? `${user.value.firstname} ${user.value.lastname}` : ''
    });

    onMounted(async () => {
      if (isAuthenticated) {
        await Promise.all([loadCart(), loadUser()]);
      }
    });

    return {
      cartItems: computed(() => (cart.value?.items?.length ?? 0)),
      toggleLoginModal,
      logOutUser,
      goToCart,
      gotToMyAccount,
      isAuthenticated,
      userName,
      user,
      cart,
      userLoading,
    };
  },
});
